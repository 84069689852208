@font-face {
    font-family: kei;
    src: url(../kei.ttf);
}

@font-face {
    font-family: Robo;
    src: url(../robo2.ttf);
}

h1 {
    font-family: kei;
    color: #fff;
    letter-spacing: .5rem;
}

.herotext {
    font-family: Robo;
    color: #fff;
    letter-spacing: .2rem;
}

.sec1 {
    background-image: url(./images/home4.jpg);
}

.sec1 h1 {
    font-size: 120px;
    font-weight: bolder;
}

@media (max-width: 768px) {
    .sec1 h1 {
        font-size: 60px;
    }
}

.title {
    text-align: center;
}

.marquee-container {
    overflow: hidden;
    background-color: #fff;
}

.marquee-text {
    display: inline-block;
    white-space: nowrap;
    animation: marquee 300s linear infinite;
    font-size: 30px;
    font-family: kei;
}

.marquee-container2 {
    overflow: hidden;
    background-color: #000;
}
.marquee-text2 {
    display: inline-block;
    white-space: nowrap;
    animation: marquee 300s linear infinite;
    font-size: 30px;
    font-family: kei;
    color: #fff;
}

.marquee-container3 {
    overflow: hidden;
    background-color: #fff;
}
.marquee-text3 {
    display: inline-block;
    white-space: nowrap;
    animation: marquee 300s linear infinite;
    font-size: 30px;
    font-family: kei;
    color: #000;
}

@keyframes marquee {
    0% {
    transform: translateX(0);
    }
    100% {
    transform: translateX(-100%);
    }
}

.aa-para {
    background-size: contain;
    background-attachment: fixed;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.sec2 {
    background-color: #fff;
    font-family: Robo;

}

.sec2 p {
    color: #000;
}

.sec2 h2 {
    color: #000;
    font-family: kei;
    letter-spacing: .2rem;
}

.sec2 span {
    background-color: #707d97;
    border: #525665 solid 1px;
}

.sec-2-skills {
    background-color: #000;
    padding: 20px;
    font-family: robo;
    color: #fff;
}

.sec-2-skills h2 {
    color: #fff;
    font-family: kei;
}


.bb-para {
    height: 60vh;
    width: 100%;
    color: white;
    background-size: 100vh;
    background-attachment: fixed;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-image: url(./images/home6.jpg);
}
@media (max-width: 768px) {
    .bb-para {
        height: 40vh;
    }
}


.sec3 {
    color: #000;
    background-color: #fff;
    font-family: Robo;
}

.sec3 h2 {
    font-family: kei;
    color: #000;
    letter-spacing: .2rem;
}

.projects {
    display: flex;
    flex: auto;
    flex-wrap: wrap;
    gap: 100px;
    justify-content: center;
    flex: auto;
    padding: 25px;
}

.project {
    padding: 10px;
    background-color: #000;
    color: #fff;
    flex-basis: 0;
    flex-grow: 1;
    position: relative;
    /* max-height: 300px; */
}

.project img {
    max-height: 250px;
    object-fit: cover;
}

.seemore {
    color: #fff;
    font-family: Robo;
    background-color: #000;
    padding: 10px;
    transition: all 0.5s ease-in-out;
}

.seemore:hover {
    background-color: #fff;
    color: #000;
}

.project-img {
    width: 100%;
    filter: grayscale(1);
    transition: all 0.2s ease-in-out;
}

.project-img:hover {
    filter: grayscale(0);
}

.project a {
    color: #fff;
    font-family: Robo;
    background-color: #000;
    padding: 5px;
    transition: all 0.2s ease-in-out;
    text-align: right;
}

.project a:hover {
    background-color: #525665;
}

.project p {
    margin-bottom: 10px;
}

.sec3-box {
    color: #b1b9c1;
}

.cc-para {
    background-size: 100vh;
    background-attachment: fixed;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 60vh;
    background-image: url(./images/home7.jpg);
}

@media (max-width: 768px) {
    .cc-para {
        height: 40vh;
    }
}

.sec4 h2 {
    font-family: kei;
    letter-spacing: .2rem;
    color: #fff;
}

.sec4 p {
    font-family: Robo;
    color: #fff;
}

.seemore2 {
    color: #000;
    font-family: Robo;
    background-color: #fff;
    padding: 10px;
    transition: all 0.5s ease-in-out;
}

.seemore2:hover {
    background-color: #000;
    color: #fff;
}

.fancy-link {
    background-color: #b1b9c1;
    color: #525665;
    font-size: 20px;
    font-weight: 600;
    font-family: kei;
    text-align: center;
    padding: 10px;
    margin-top: 15px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    border: 1px solid #525665;
    box-shadow: 0 0 0 0 #525665;
    width: 50%;
    border-radius: 10em;
    }

.fancy-link:hover {
    transform: translateY(-4px) translateX(-2px);
    box-shadow: 2px 5px 0 0 #525665;
};

.fancy-link:active {
    transform: translateY(2px) translateX(1px);
    box-shadow: 0 0 0 0 #525665;
};

.dd-para {
    width: 100%;
    background-size: cover;
    background-attachment: fixed;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.pro-page {
    color: #fff;
}

.pro-page p {
    font-family: Robo;
    color: #fff;
}

.pro-page h2 {
    font-family: kei;
    color: #000;
    letter-spacing: .2rem;
    font-weight: 600;
}

.pro-page h3 {
    font-family: Robo;
    color: #000;
    letter-spacing: .2rem;
    font-weight: 600;
}

.pro-page h1 {
    color: #fff;
    font-weight: 600;
}

.pro-card {
    background-color: #fff;
}

.pro-card a {
    color : #000;
    transition: all 0.3s ease-in-out;
}

.pro-card a:hover {
    color: #494949;
}

.pro-card p {
    color: #000;
}

.about {
    color: #fff;
    padding-left: 10px;
    padding-right: 10px;
}

.about2 {
    background-color: #fff;
    color: #000;
    padding-left: 10px;
    padding-right: 10px;
}

.about2 h2 {
    font-family: kei;
    letter-spacing: .2rem;
    font-weight: 600;
}

.blue-div {
    background-color: #000;
}

.about-page {
    font-family: Robo;
}

.about-page h2 {
    font-family: kei;
    letter-spacing: .2rem;
    font-weight: 600;
}

.project-page {
    color: #fff;
    font-family: Robo;
    padding-left: 8px;
    padding-right: 8px;
}

.project-page h2 {
    font-family: kei;
    letter-spacing: .2rem;
    font-weight: 600;
    color: #fff;
}

.tech-stack {
    width: 100%;
    background-size: cover;
    background-attachment: fixed;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-image: url(./images/home3.jpg);
}

.tech-stack h2 {
    font-family: kei;
    letter-spacing: .2rem;
    font-weight: 600;
    color: #000;
    font-size: 35px;
}

.tech-entry {
    text-align: left;
    background-color: #fff;
    color: #000;
    padding: 20px;
}

.project-page a {
    font-family: kei;
    color: #fff;
    font-weight: 600;
    transition: all 0.2s ease-in-out;
    letter-spacing: .2rem;
}

.project-page a:hover {
    color: #494949;
}

.project-page h3 {
    font-family: kei;
    color: #fff;
    font-weight: 600;
    letter-spacing: .2rem;
}

.more-info {
    margin-top: 20px;
    text-align: right;
    position: relative;
    bottom: 0;
}

/* .about-text {
    position: relative;
} */

.read-more {
    color: #d7ccbc;
    font-family: Robo;
    background-color: #3d3731;
    border: #d7ccbc solid 1px;
    padding: 8px;
    transition: all 0.2s ease-in-out;
    text-align: right;
}

.read-more:hover {
    background-color: #494949;
}

.pro-page a {
    font-family: Robo;
}

.input {
    border: 1px solid #000;
    padding: 15px;
    color: #fff;
    background-color: #000;
    font-size: small;
    font-weight: bold;
    font-family: Robo;
    }

.input:focus {
    outline-color: #fff;
    color: #fff;
    box-shadow: 5px 5px #494949;
    }

.input-label {
    color: #3f4858;
    font-family: Robo;
    font-weight: bold;
}

.comic-button {
    display: inline-block;
    padding: 10px 20px;
    font-family: Robo;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    color: #fff;
    background-color: #000;
    transition: all 0.3s ease;
}

.comic-button:hover {
    background-color: #fff;
    color: #000;
    border: 1px solid #000;
    box-shadow: 5px 5px 0px #494949;
}

.comic-button:active {
    background-color: #494949;
    box-shadow: none;
    transform: translateY(4px);
}

.contact {
    font-family: Robo;
}

.contact h1 {
    font-family: kei;
    letter-spacing: .2rem;
    font-size: 60px;
}

.card {
    --font-color: #fff;
    --font-color-sub: #fff;
    --bg-color: #000;
    --main-color: #494949;
    width: 300px;
    height: 110%px;
    background: var(--bg-color);
    border: 1px solid var(--main-color);
    box-shadow: 4px 4px var(--main-color);
    padding: 20px;
    gap: 10px;
    font-family: Robo;
}

.card img {
    min-height: 200px;
    object-fit: cover;
}

.card-title {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    color: var(--font-color);
    font-family: kei;
    padding-top: 10px;
}

.card-subtitle {
    font-size: 14px;
    font-weight: 400;
    color: var(--font-color);
    padding-top: 10px;
}

.card-footer {
    text-align: right;
    padding-top: 10px;
    color: #fff;
}

.card-footer a {
    transition: all ease-in-out 0.3s;
}

.card-footer a:hover {
    color: var(--main-color);
}

.contact-links {
    color: #fff;
    text-align: center;
}

.contact-links a {
    transition: all ease-in-out 0.3s;
}

.contact-links a:hover {
    color: #494949;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  max-width: 800px;
  margin: auto;
  background-color: black;
  @media (max-width: 800px) {
    max-width: 100%;
  }
}

@font-face {
    font-family: kei;
    src: url(kei.ttf);
}

header a {
    font-family: kei;
    color: #ffffff;
    transition: all 0.2s ease-in-out;
    letter-spacing: .2rem;
}

header a:hover {
    color: #a9bbc5;
}

header {
    background-color: #000000;
    padding: 15px;
}

footer {
    font-family: kei;
    letter-spacing: .2rem;
    background-color: #000000;

}

footer a {
    max-width: 25px;
    transition: all 0.2s ease-in-out;
}

footer span {
    color: #ffffff;
}

footer a:hover {
    opacity: 0.7;
}
